<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <v-col cols="12" sm="4" v-for=" item in company_list_info" :key="item.id">
                <v-card >
                    <v-card-title class="c-title" style="text-align: center;">
                        <center>
                            {{item.Sr_No}}.{{item.company}}
                        </center>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-chip class="ma-1 text" color="#fff" text-color="black" label>
                                    <v-icon left>mdi-rename-box</v-icon>
                                    <strong class="black--text text--lighten-1">{{item.placementtype}}</strong>
                                </v-chip>
                                <!-- <v-chip class="ma-1 text" color="#fff" text-color="black" label>
                                    <v-icon left>mdi-calendar</v-icon>
                                    <div v-if=" '{{item.from_schedule_date}}' == '{{item.to_schedule_date}}'">
                                    
                                         {{item.from_schedule_ma-date}}
                                    </div>
                                    <template v-else>
                                        <v-label ><strong class="black--text text--lighten-1">{{item.from_schedule_date}} - {{item.to_schedule_date}}</strong></v-label>
                                       
                                    </template>
                                    
                                </v-chip> -->
                                <!-- <v-chip class="ma-1 text"  id="cri_selection_proc" text-color="white" label @click="viewCriteria(item.id)">
                                    <v-icon left>mdi-playlist-check</v-icon>
                                    
                                    Criteria & Selection Process
                                </v-chip>
                                 <v-chip v-if="item.current_round_number == 0 && item.current_round_number != null && item.current_round != null" class="ma-1 text" id="round_details" text-color="black" label>
                                    <v-icon left>mdi-directions</v-icon>
                                    <span>Applied</span>
                                </v-chip>
                                <v-chip v-else-if="item.current_round_number && item.current_round" class="ma-1 text" id="round_details" text-color="black" label>
                                    <v-icon left>mdi-directions</v-icon>
                                    {{item.current_round_number}}:{{item.current_round}}
                                </v-chip> -->
                                
                               
                                <v-chip class="ma-1 text"  id="moreinfonew" text-color="black" label @click="viewRoundinfo(item.id)">
                                    <v-icon left>mdi-more</v-icon>
                                    Track Result
                                </v-chip>

                                <div class="text-center">
                                <v-btn class="logi ma-5 mt-8 center" color="primary" dark v-if="item.isoffertobeacceptedbystudent==true && item.selected==true && !item.learneraccepted" @click="acceptLetter(item)" >
                                        Accept
                                </v-btn>
                                <!-- <v-btn class="ma-5 mt-8" id="rej" v-if="item.isoffertobeacceptedbystudent==false && item.selected" @click="rejectLetter(item)">
                                        Reject
                                </v-btn>-->
                                </div>

                                <v-row style="margin-left:10px;margin-top:10px" v-if="item.selected && item.learneraccepted && item.offer_uploaded == false">
                                 <label>Upload Offer Letter :</label>
                                    <input type="hidden" v-model="item.short_name"/>
                                    <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size required="true"
                                    clearable v-model="savedata.file"
                                    ></v-file-input>
                        
                                <v-btn color="primary darken-1" class="mx-auto" dark @click="save(item)">Save</v-btn>             
                                </v-row>

                             <v-alert id="studentselected"  type="success" class="ma-1 mt-4" v-if="item.offer_uploaded == true">
                                    Congratulations! You Are Selected For This Company.
                                </v-alert>

                                <!-- <v-alert type="info" class="ma-4 mt-4" v-if="item.selected==false && item.selected != null">
                                    Wait For Final Result..!
                                </v-alert>              -->

                                <!-- <v-img contain  alt="School Logo" style="width: 230px; height: 188px" src="./tp.jpg"></v-img> -->
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>   
            </v-col>
        </v-row>

        <!-- <v-card>
              <v-card-title>Application Tracking</v-card-title>
              <v-card-text>
              <template>
                  <v-simple-table >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Company Name</th>
                          <th class="text-left">Offer Type</th>
                          <th class="text-left">Schedule Date</th>
                          <th class="text-left">Selection Process Details</th>
                          <th class="text-left">Round Tracking Status</th>
                          <th class="text-left">More Info.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for=" item in company_list_info" :key="item.id">
                          <td>{{item.Sr_No}}.{{item.company}}</td>
                          <td>{{item.placementtype}}</td>
                          <td>{{item.from_schedule_date}}</td>
                          <td>
                              <v-chip
                                class="ma-2"
                                color="success"
                                @click="viewCriteria(item.id)">
                                Criteria and Selection Process
                              </v-chip> 
                          </td>
                          <td>{{item.current_round_number}}:{{item.current_round}}</td>
                          <td> 
                            <v-chip
                              class="ma-2"
                              color="red"
                              text-color="white"
                              @click="viewRoundinfo(item.id)">
                              Know More
                            </v-chip>
                            <v-btn class="ma-2" text icon color="blue lighten-2"  v-if="item.selected">
                              <v-icon>mdi-thumb-up</v-icon>
                            </v-btn>
                            <v-btn class="ma-2" color="primary" dark  v-if="item.selected" @click="acceptLetter(item)">Accept
                                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card-text>
            </v-card>-->
        <v-row justify="center">
            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar class="c-title" >
                        
                        <v-btn icon dark @click="dialog = false">
                            
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                        
                        <v-toolbar-title >Criteria And Selection Procedure</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <v-row v-if="company!=null">
                          <v-col cols="12" sm="2">
                            <span class="lbl">Company</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.name}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Company Type</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.companytype}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Schedule Date</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.from_schedule_date}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Industry Type</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            :  <span v-for="(i,index) in company.industry" :key=index> {{i}}<br></span>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Min Package</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.minPackage}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Max Package</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.maxPackage}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Job Locations</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            :<span v-for="(i,index) in company.placement_process_locations" :key=index>  {{i}}</span>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Job Designation</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.job_description}}
                        </v-col>
                        
                    </v-row>
                    <v-row v-if="company!=null"> 
                      <v-col cols="12" sm="2">
                            <span class="lbl">Offering</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company.placementtype}}
                        </v-col>
                         
                        <v-col cols="12" sm="2">
                            <span class="lbl">Branches</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            <ul id="v-for-object" class="demo">
                                <li v-for="program in company.branches" :key="program">{{ program }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Organization</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            <ul id="v-for-object" class="demo">
                                <li v-for="org in company.institutes" :key="org">{{ org }}</li>
                            </ul>
                        </v-col>
                        
                    </v-row>
                    
                        <!-- {{company_list}} -->
                      <!--
                        <v-row justify="center">
                            <template v-if="company!=null">
                                <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Company Name :: {{company.name}}
                                    </v-chip>
                                </v-col>
                                <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Schedule Date :: {{company.from_schedule_date}}
                                    </v-chip>
                                </v-col>
                                <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Company Type :: {{company.companytype}}
                                    </v-chip>
                                </v-col>
                                <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Domain :: {{company.industry}}
                                    </v-chip>
                                </v-col>
                            </template>
                        </v-row>
                        <v-row align="center" v-if="company!=null">
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Offering :: {{company.offering}}
                                </v-chip>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Branches:
                                     Branches :: {{company.branches}} 
                                </v-chip>
                                <li v-for="branches in company.branches" :key="branches">
                                    {{ branches }}
                                </li>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Institutes:
                                     Institutes ::  {{company.institutes}} 
                                </v-chip>
                                <li v-for="institutes in company.institutes" :key="institutes" style="margin-left: 20px;">
                                    {{ institutes }}
                                </li>
                            </v-col>
                        </v-row>
                        <v-row align="center" v-if="company!=null">
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Min Package :: {{company.minPackage}}
                                </v-chip>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Max Package :: {{company.maxPackage}}
                                </v-chip>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    JOB Description :: {{company.job_description}}
                                </v-chip>
                            </v-col>

                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    JOB Location :: {{company.placement_process_locations}}
                                </v-chip>
                            </v-col>
                        </v-row> -->
                        <v-toolbar  dense class="tc-title">
                                <v-toolbar-title>Selection Criteria And Selection Procedure</v-toolbar-title>
                                <v-spacer></v-spacer>
                        </v-toolbar> 
                              
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card style=" margin: 8px; height:100%">
                                        <v-card-title class="c-title">Selection Criteria</v-card-title>
                                        <v-card-text>
                                             <v-row>    
                                                <v-col cols="6" sm="4"> 
                                                    <span class="lbl">Specific Criteria :</span>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                  {{company.specific_criteria}}   
                                                </v-col>
                                              </v-row>
                                            <v-row>    
                                                <v-col cols="6" sm="4"> 
                                                  <span class="lbl"> Live Backlog Allowed :</span>
                                                </v-col>
                                                <v-col cols="6" sm="4"> 
                                                <span v-if="company.is_live_backlog_allowed">
                                                    <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                                                </span>
                                                <span v-else>
                                                    <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                                                </span>
                                                </v-col>
                                            </v-row>
                                            <v-data-table :headers="eli_headers" :items="criteria_list" :search="eli_search"> </v-data-table> 
                                        </v-card-text>
                                    </v-card> 
                                </v-col>
                            
                            
                           <v-col cols="12" sm="6">
                               <v-card style=" margin: 8px; height:100%">
                                   <v-card-title class="c-title">Selection Procedure</v-card-title>
                               <v-row>
                                   
                                 <v-col cols="12" sm='6' v-for="item in cor_list" :key="item">
                                     
                                        <v-card style=" margin: 8px; height:100%" >
                                            <v-card-title class="tc-title">
                                               Round :  {{item.round_number}}
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row>    
                                                    <!-- <v-col cols="6" sm="4">
                                                        <span class="lbl">Round Details</span>
                                                    </v-col>
                                                    <v-col cols="6" md="6">
                                                        : -->
                                                        <v-row justify="center" style=" margin: 8px;">
                                                                <v-btn text >
                                                                {{item.round_details}}
                                                            </v-btn>
                                                        </v-row>
                                                    <!-- </v-col> -->
                                                </v-row> 
                                                <v-row justify="center" v-if="item.round_isfinal">    
                                                    <!-- <v-col cols="12" sm="12"> -->
                                                        <!-- <span class="lbl">{{item.round_isfinal}}</span> -->
                                                       
                                                        <strong class="green--text text--lighten-1"> {{item.round_isfinal}}</strong>
                                                    <!-- </v-col> -->
                                                    <!-- <v-col cols="6" md="6">
                                                     {{item.round_isfinal}}
                                                    </v-col> -->
                                                </v-row> 
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                   
                               </v-row>
                               </v-card>
                           </v-col>
                                    <!-- <v-card>
                                        <v-card-title>Selection Procedure</v-card-title>
                                        <v-card-text>
                                            <v-data-table :headers="cor_headers" :items="cor_list" :search="cor_search"> </v-data-table>
                                        </v-card-text>
                                    </v-card>  -->
                                
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="dialog1" width="auto">
                <v-card>
                    <v-card-title class="c-title">
                         <v-row justify="space-between" > 
                        Student Company Round Details
                        <v-btn  icon dark @click="dialog1 = false">
                               <v-icon color="black">mdi-close</v-icon>
                               </v-btn>
                          </v-row>  
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="center">
                            <template v-if="company!=null">
                                <!--<v-col md="3" sm="3">
                                   <v-chip class="ma-2" color="grey" label text-color="white">
                                       
                                    </v-chip> 
                                </v-col> -->
                                <v-row>
                                    <v-col md="6" sm="3">
                                    <span class="lbl">  Company Name </span>
                                    </v-col>
                                   <v-col md="6" sm="3">
                                        :  {{company.name}}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" sm="3">
                                    <span class="lbl">  Industry </span>
                                    </v-col>
                                    <v-col md="6" sm="3">
                                        : <span v-for="(i,index) in company.industry" :key=index> {{i}}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="3" sm="3">
                                    <span class="lbl">  Offering</span>
                                    </v-col>
                                   <v-col md="4" sm="4">
                                        :  {{company.placementtype}}
                                    </v-col>
                                </v-row>
                                      
                               
                                 <!-- <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Domain :: {{company.industry}}
                                    </v-chip>
                                </v-col>
                                <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Offering :: {{company.offering}}
                                </v-chip>
                            </v-col> -->
                               <!-- <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Schedule Date :: {{company.from_schedule_date}}
                                    </v-chip>
                                </v-col>
                                <v-col md="3" sm="3">
                                    <v-chip class="ma-2" color="grey" label text-color="white">
                                        Company Type :: {{company.companytype}}
                                    </v-chip>
                                </v-col>
                               -->
                            </template>
                        </v-row>
                       <!-- <v-row align="center" v-if="company!=null">
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Offering :: {{company.offering}}
                                </v-chip>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Branches:
                                    Branches :: {{company.branches}} 
                                </v-chip>
                                <li v-for="branches in company.branches" :key="branches">
                                    {{ branches }}
                                </li>
                            </v-col>
                            <v-col md="3" sm="3">
                                <v-chip class="ma-2" color="grey" label text-color="white">
                                    Institutes:
                                    Institutes ::  {{company.institutes}} 
                                </v-chip>
                                <li v-for="institutes in company.institutes" :key="institutes" style="margin-left: 20px;">
                                    {{ institutes }}
                                </li>
                            </v-col>
                        </v-row> -->
                        <v-row>
                         <v-col cols="12" sm='3' v-for="item in studentround_list" :key="item">
                                                  
                                        <v-card >
                                            <v-card-title class="c-title">
                                                <span v-if= "item.round_number == 0 ">
                                                Application
                                                </span>
                                              <span v-else >Round   {{item.round_number}} : {{item.round_details}}</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <!-- <v-row>    
                                                    <v-col cols="6" sm="4">
                                                        <span class="lbl">Round Details</span>
                                                    </v-col>
                                                    <v-col cols="6" md="6">
                                                        : {{item.round_details}}
                                                    </v-col>
                                                </v-row>  -->
                                                <v-row>    
                                                 <v-col cols="6" sm="4" v-if= "item.round_number == 0 ">
                                                 <span class="lbl"> Received</span>
                                                 </v-col>
                                                    <v-col v-else cols="6" sm="4">
                                                        <span class="lbl"> Result</span>
                                                    </v-col>
                                                    <v-col cols="6" md="6">
                                                        :  <span v-if="item.round_isfinal=='Pass' || item.round_number == 0">
                                                                <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                                                            </span>
                                                            <span v-else>
                                                                <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                                                            </span>
                                                    </v-col>
                                                </v-row> 
                                            </v-card-text>
                                        </v-card>
                            
                                    </v-col>


                                     <v-row justify="center">
                                    <div style="margin-top:40px;margin-bottom:10px"  v-if="this.finalselected == 'finalselected' ">
                                      <v-card>
                                            <v-card-title class="c-title">
                                              You Are Selected
                                            </v-card-title>
                                      </v-card>
                                    </div>
                                    </v-row>
                                    <br>

                                    
                        </v-row>
                       <!-- <v-data-table :headers="studround_headers" :items="studentround_list" :search="studround_search">
                            <template v-slot:item.round_isfinal="{ item }">
                                <span v-if="item.round_isfinal=='Pass'">
                                    <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                                </span>
                                <span v-else>
                                    <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                                </span>
                            </template>
                        </v-data-table> -->
                        <v-row justify="center">
                        <v-btn  class="logi" outlined text @click="dialog1 = false">
                                Close
                            </v-btn>
                       
                        <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
                       
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    
    export default {
        data: () => ({
            snackbar_msg: "",
            snackbar: false,
            color: "",
            company: "",
            company_list_info: null,
            selected_company: null,
            criteria_list: null,
            studentround_list: null,
            cor_list: null,
            checkbox: null,
            dialog: false,
            dialog1: false,
            sound: true,
            widgets: false,
            company_list_info_details: [],
            eli_search: "",
            finalselected:"",
            loading: false,
            savedata: {
        file: null,
        company_name:'',
      },
            getImg(index) {
        return require(this.images);
        images:"./assets/accept.jpg"
      },
            eli_headers: [
                { text: "SR.No", value: "cri_srno" },
                { text: "Degree", value: "degree" },
                { text: "Criteria(Percentage/CPI)", value: "percentage_cpi" },
            ],
            cor_search: "",
            cor_headers: [
                { text: "Round ", value: "round_number" },
                { text: "Round Details", value: "round_details" },
                { text: "Final Round", value: "round_isfinal" },
            ],
            studround_search: "",
            studround_headers: [
                { text: "Round ", value: "round_number" },
                { text: "Round Details", value: "round_details" },
                { text: "Round Result", value: "round_isfinal" },
            ],
        }),
        computed: {},
        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        methods: {
            save(item) {
       
                  if(this.savedata.file == null){
                       this.showSnackbar("#b71c1c", "Please Select File...");
                }
                 else {
                    let formData = new FormData();
                    formData.append("file", this.savedata.file);
                    formData.append("shortname",item.short_name);
                   // formData.append("company_name", this.savedata.company_name);
                   // window.alert(JSON.stringify(formData))
                    axios
                        .post("/TPOCompanyScheduling/saveOfferletter", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                            
                                this.showSnackbar("#4caf50", "File Addded Successfully...");
                                 // document.getElementById("AddFile").style.display = "block";
                                
                                this.mounted();
                            } else {
                             //    this.dialog_upload=false;
                                this.showSnackbar("#b71c1c", res.data.msg);
                              //      document.getElementById("AddFile").style.display = "block";
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });

                    
                }
               
                
            },
          


            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            viewCriteria(item) {
                const formData = {
                    companyOfferingLearnerid: item,
                };
                axios
                    .post("/StudentApplicationTrack/viewCriteriaInfo", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "200") {
                            //4CAF50
                            this.company = res.data.company_list;
                            this.criteria_list = res.data.criteria_list;
                            this.cor_list = res.data.cor_list;
                             this.finalselected = res.data.finalselected
                            this.dialog = true;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    })
                    .finally(() => {});
            },
            acceptLetter(item) {
                 if (!confirm(  "Are you sure, you want to accept this offer!!!"))
                 {
                      return;
                 }
                 //alert(JSON.stringify(this.company_list_info.learner_name))
                const formData = {
                   // item: true,
                    companyOfferingLearnerid: item.id,
                    // learner_name: this.company_list_info.learner_name,
                    // reg_no: this.company_list_info.reg_no,
                };
                //alert(formData.learner_name + " " +formData.reg_no)
                axios
                    .post("/StudentApplicationTrack/acceptLetterStudent", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "200") {
                            //4CAF50
                            this.showSnackbar("#4CAF50", "You Have Accepted This Company ...");
                            window.location.reload();
                        } else if (res.data.msg == "400"){
                             this.onload();
                            this.showSnackbar("#b71c1c", "You Have Already Accepted Offer from One Company");
                        }else {
                            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    })
                    .finally(() => {});
            },

            rejectLetter(item) {
            if (
                !confirm(
                    "Are you sure, you want to Reject this offer!!!"
                    )
                )
                return;
                const formData = {
                    //item:false,
                    companyOfferingLearnerid: item,
                };
                axios
                    .post("/StudentApplicationTrack/acceptLetterStudent", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "200") {
                            //4CAF50
                            this.showSnackbar("red", "You have Rejected This Compnay ...");
                        } else {
                             this.onload();
                            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    })
                    .finally(() => {});
            },
            

            viewRoundinfo(item) {
                const formData = {
                    companyOfferingLearnerid: item,
                };
                axios
                    .post("/StudentApplicationTrack/viewRoundInfo", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "200") {
                            //4CAF50
                            this.company = res.data.company_list;
                            this.studentround_list = res.data.studentround_list;
                             this.finalselected = res.data.finalselected
                            this.dialog1 = true;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    })
                    .finally(() => {});
            },
        },
        mounted() {
            
            axios
                .post("/StudentApplicationTrack/applicationTrack")
                .then((res) => {
                    //console.log("Track DATA"+JSON.stringify(res.data));
                    if (res.data.msg == "200") {
                        //4CAF50
                        //window.console.table(`DATA RECEIVED= ${JSON.stringify(res.data.company_list_info)}`)
                        this.company_list_info = res.data.company_list_info;
                        // alert(JSON.stringify(this.company_list_info_details.learner_name))
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => {});
        },
    };
</script>
<style scoped>
.ec-title {
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #fff;
    }
    .c-title {
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #fff;
    }
    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
    .logi {
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #fff;
    }
    .card-title {
        background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #fff;
    }
    .lbl {
        color: #1705e6;
    }
    .text {
        width: 700px;
        margin: none;
    }
    #moreinfo {
        background-image: linear-gradient(-90deg, #ee6b76, #f70411);
        color: #fff;
    }
     #moreinfonew {
        background-image: linear-gradient(-90deg, #FFA500, #FFA500);
        
    }
    #rej
    { 
      background-image: linear-gradient(-90deg, #ee6b76, #f70411);
      color: #fff;  
    }
    #cri_selection_proc
    {
            background-image: linear-gradient(-90deg, #9bdf99, #07a73cfb);
            color: #fff;
    }
    #round_details
    {
            background-image: linear-gradient(-90deg, #f2faf22f, #0b632800);
            color: #fff;
    }
    .logi1
    {
        background-image: linear-gradient(-100deg, #6cf389, #0b9761);
       color: #fff;
    } 
    #studentselected
    { 
        border-radius: 5px;
        font-size: 15px;
        height:66px;
        margin-top:1px;
    }
    #btndanger
    {
        color:red
    }
    
</style>
